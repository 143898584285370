// Function to get IP Address from an external link to fetch the global IP
const getIPAddress = async () => {
	try {
		const response = await fetch(
			'https://europe-north1-heyflow-custom-solutions.cloudfunctions.net/fetchIp',
		);

		const data = await response.json();
		return data.ip || 'Unable to fetch IP address';
	} catch (error) {
		console.error('Error fetching IP address:', error);

		return 'Error fetching IP address';
	}
};

// IP Address Capture
const fetchIpAddress = async () => {
	try {
		const ipInputField = document.querySelector(
			`[data-variable="ipVariable"]`,
		);

		if (ipInputField) {
			const ipAddress = await getIPAddress();

			ipInputField.value = ipAddress;
		} else {
			console.error(`Element with data-variable="${ipVariable}" not found`);
		}
	} catch (error) {
		console.error('Error: ', error);
	}
};

// Implementation of IP Fetch
window.addEventListener('heyflow-init', event => {
	fetchIpAddress();
});